import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut, setPersistence, browserLocalPersistence, reload } from 'firebase/auth';
import { collection, getDocs, doc, getDoc, query, where, orderBy } from 'firebase/firestore';
import { useSubscription } from '../components/UserSubscription';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { UseHostDetection, KNOWN_HOSTS } from '../hooks/UseHostDetection';

export const AppContext = createContext();

const CHARACTERS_CACHE_KEY = 'chatCharactersCache';
const CHARACTERS_CACHE_EXPIRY = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

export const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [chatCharacters, setChatCharacters] = useState(null);
  const [isCharactersLoading, setIsCharactersLoading] = useState(true);
  const { subscriptionStatus, isLoading: isSubscriptionLoading, refetch: refetchSubscription } = useSubscription();
  const [characterOverrides, setCharacterOverrides] = useState({});
  const [isOverridesLoading, setIsOverridesLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(true); // Add loading state for auth
  const [hasRefreshedOnReturn, setHasRefreshedOnReturn] = useState(false);
  const visibilityChangeRef = useRef(null);
  const currentHost = UseHostDetection();

  const fetchAndCacheCharacters = useCallback(async (forceUpdate = false) => {
    if (!currentHost) {
      setIsCharactersLoading(true);
      return;
    }

    const cachedData = localStorage.getItem(CHARACTERS_CACHE_KEY);
    if (cachedData && !forceUpdate) {
      const { data, timestamp, host } = JSON.parse(cachedData);
      
      if (host !== currentHost) {
        console.log('Host mismatch, forcing update:', { cached: host, current: currentHost });
        fetchAndCacheCharacters(true);
        return;
      }
      
      if (Date.now() - timestamp >= CHARACTERS_CACHE_EXPIRY) {
        console.log('Cache expired, forcing update');
        fetchAndCacheCharacters(true);
        return;
      }
      
      console.log('Using cached data for host:', currentHost);
      setChatCharacters(data);
      setIsCharactersLoading(false);
      return;
    }

    try {
      console.log('Fetching fresh data for host:', currentHost);
      const charactersRef = collection(db, 'chat_characters');
      let charactersQuery;
      
      if (currentHost === KNOWN_HOSTS.THIRST) {
        charactersQuery = query(charactersRef, 
          where('__name__', '>=', 'thirst_'),
          where('__name__', '<', 'thirst_\uf8ff')
        );
      } else {
        charactersQuery = query(charactersRef,
          where('__name__', '<', 'thirst_')
        );
      }

      const querySnapshot = await getDocs(charactersQuery);
      const charactersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      localStorage.setItem(CHARACTERS_CACHE_KEY, JSON.stringify({
        data: charactersData,
        timestamp: Date.now(),
        host: currentHost
      }));
      
      setChatCharacters(charactersData);
    } catch (error) {
      console.error('Error fetching characters:', error);
    } finally {
      setIsCharactersLoading(false);
      checkDataReady();
    }
  }, [currentHost]);

  const fetchCharacterOverrides = useCallback(async () => {
    if (user) {
      setIsOverridesLoading(true);
      const userDocRef = doc(db, 'users', user.email.toLowerCase());
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCharacterOverrides(userData.character_customizations || {});
        }
      } catch (error) {
        console.error('Error fetching character overrides:', error);
      } finally {
        setIsOverridesLoading(false);
        checkDataReady();
      }
    } else {
      setCharacterOverrides({});
      setIsOverridesLoading(false);
      checkDataReady();
    }
  }, [user]);

  const checkDataReady = useCallback(() => {
    if (!isCharactersLoading && !isOverridesLoading && !isAuthLoading) {
      setIsDataReady(true);
    }
  }, [isCharactersLoading, isOverridesLoading, isAuthLoading]);

  useEffect(() => {
    fetchAndCacheCharacters();
    fetchCharacterOverrides();
  }, [fetchAndCacheCharacters, fetchCharacterOverrides]);

  const refreshUserStatus = useCallback(async () => {
    if (user && !hasRefreshedOnReturn) {
      try {
        await reload(user);
        setUser({ ...user, reload: Date.now() }); // Force update
        await refetchSubscription();
        setHasRefreshedOnReturn(true);
      } catch (error) {
        console.error('Error refreshing user status:', error);
      }
    }
  }, [user, hasRefreshedOnReturn, refetchSubscription]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && !hasRefreshedOnReturn) {
        refreshUserStatus();
      }
    };

    visibilityChangeRef.current = handleVisibilityChange;
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refreshUserStatus, hasRefreshedOnReturn]);

  const handleAuthStateChange = useCallback((currentUser) => {
    console.log('Auth state changed:', currentUser);
    setUser(currentUser);
    setIsAuthLoading(false); // Set auth loading to false once auth state is determined
    setHasRefreshedOnReturn(false); // Reset the flag when auth state changes
  }, []);

  useEffect(() => {
    const initAuth = async () => {
      await setPersistence(auth, browserLocalPersistence);
      const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
      return unsubscribe;
    };

    initAuth();
  }, [handleAuthStateChange]);

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('subscriptionStatusCache');
      // The handleAuthStateChange function will automatically set the user to null
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const processReferralCode = useCallback(async (referralCode) => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const functions = getFunctions();
      const processReferral = httpsCallable(functions, 'processReferralCode');
      const result = await processReferral({ referral_code: referralCode });
      console.log('Referral code processed:', result.data);
      return result.data;
    } catch (error) {
      console.error('Error processing referral code:', error);
      throw error;
    }
  }, [user]);

  const contextValue = {
    user,
    setUser,
    subscriptionStatus,
    isSubscriptionLoading,
    refetchSubscription,
    chatCharacters,
    isCharactersLoading,
    fetchAndCacheCharacters: () => fetchAndCacheCharacters(true),
    logout,
    characterOverrides,
    setCharacterOverrides,
    isOverridesLoading,
    fetchCharacterOverrides,
    isDataReady,
    refreshUserStatus,
    processReferralCode,
    currentHost,
    KNOWN_HOSTS
  };

  if (isAuthLoading || !currentHost) {
    return <div>Loading...</div>;
  }

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};
