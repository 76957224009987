import React, { useState, useContext, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import { AppContextProvider, AppContext } from './context/AppContext';
import { View, StyleSheet, Text } from 'react-native';
import { BackgroundImageProvider } from './components/BackgroundImageContext';
import { Helmet } from 'react-helmet';

const darkTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#BB86FC',
    accent: '#03DAC6',
    background: '#121212',
    surface: '#121212',
    text: '#FFFFFF',
  },
};

// Lazy imports
const Home = lazy(() => import('./components/Home'));
const Login = lazy(() => import('./components/Login'));
const AccountManagement = lazy(() => import('./components/AccountManagement'));
const AccountCreation = lazy(() => import('./components/AccountCreation'));
const PasswordReset = lazy(() => import('./components/PasswordReset'));
const ChatScreen = lazy(() => import('./components/ChatScreen'));
const FAQ = lazy(() => import('./components/FAQ'));
const Contact = lazy(() => import('./components/Contact'));
const AgeVerification = lazy(() => import('./components/AgeVerification'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const CharacterVoting = lazy(() => import('./components/CharacterVoting'));

// Add this function to handle the referral code
function useReferralCode() {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refCode = searchParams.get('ref');
    if (refCode) {
      localStorage.setItem('referralCode', refCode);
    }
  }, [location]);
}

function AppContent() {
  const { user } = useContext(AppContext);
  const [isAgeVerified, setIsAgeVerified] = useState(() => {
    return localStorage.getItem('isAgeVerified') === 'true';
  });

  // Use the referral code hook
  useReferralCode();

  useEffect(() => {
    localStorage.setItem('isAgeVerified', isAgeVerified);
  }, [isAgeVerified]);

  return (
    <Suspense fallback={<View style={styles.loadingContainer}><Text>Loading...</Text></View>}>
      <Routes>
        {!isAgeVerified ? (
          <Route path="*" element={<AgeVerification onVerified={() => setIsAgeVerified(true)} />} />
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/account" element={<AccountManagement />} />
            <Route path="/create-account" element={<AccountCreation />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/chat/:characterId/:sceneIndex" element={<ChatScreen />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/vote" element={<CharacterVoting />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

// Move getHostSpecificContent outside of App component
const getHostSpecificContent = (currentHost, KNOWN_HOSTS) => {
  switch (currentHost) {
    case KNOWN_HOSTS.THIRST:
      return {
        title: 'Thirst.Chat',
        defaultDescription: 'Thirst.Chat features uncensored erotic chatbots with lifelike responses',
        favicons: {
          png128: '/thirst-favicon-128.png',
          png32: '/thirst-favicon-32.png',
          png16: '/thirst-favicon-16.png',
        }
      };
    default: // FURCHAT
      return {
        title: 'FurChat AI',
        defaultDescription: 'FurChat AI features uncensored furry chatbots with lifelike responses',
        favicons: {
          png128: '/favicon-128.png',
          png32: '/favicon-32.png',
          png16: '/favicon-16.png',
        }
      };
  }
};

// Create a wrapped component that has access to context
function AppWithContext() {
  const { currentHost, KNOWN_HOSTS } = useContext(AppContext);
  const content = getHostSpecificContent(currentHost, KNOWN_HOSTS);

  return (
    <Helmet
      defaultTitle={content.title}
      titleTemplate={`%s - ${content.title}`}
    >
      {/* Default meta tags */}
      <title>{content.title}</title>
      <meta name="description" content={content.defaultDescription} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      
      {/* Favicon links */}
      <link rel="icon" type="image/png" sizes="128x128" href={content.favicons.png128} />
      <link rel="icon" type="image/png" sizes="32x32" href={content.favicons.png32} />
      <link rel="icon" type="image/png" sizes="16x16" href={content.favicons.png16} />
      
      {/* Global styles */}
      <style>
        {`
          body {
            background: linear-gradient(to bottom right, #ff3bfb, #4e54c8, #8a2be2);
            background-attachment: fixed;
          }
        `}
      </style>
    </Helmet>
  );
}

function App() {
  return (
    <Router>
      <AppContextProvider>
        <View style={styles.container}>
          <BackgroundImageProvider>
            <PaperProvider theme={darkTheme}>
              <AppWithContext />
              <AppContent />
            </PaperProvider>
          </BackgroundImageProvider>
        </View>
      </AppContextProvider>
    </Router>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#121212',
  },
});

export default App;
