import { useState, useEffect } from 'react';

export const KNOWN_HOSTS = {
  FURCHAT: 'furchat.ai',
  THIRST: 'thirst.chat'
};

export const UseHostDetection = () => {
  const [currentHost, setCurrentHost] = useState(null);
  
  useEffect(() => {
    const detectHost = () => {
      // Check for URL override parameter first
      const urlParams = new URLSearchParams(window.location.search);
      const overrideHost = urlParams.get('host');
      
      if (overrideHost && Object.values(KNOWN_HOSTS).includes(overrideHost)) {
        console.log('Using override host:', overrideHost);
        return overrideHost;
      }
      
      // Otherwise use actual hostname
      const hostname = window.location.hostname;
      
      // Strip 'www.' if present
      const cleanHostname = hostname.replace(/^www\./, '');
      
      // Return the matched host or default to FURCHAT
      const detectedHost = Object.values(KNOWN_HOSTS).includes(cleanHostname) 
        ? cleanHostname 
        : KNOWN_HOSTS.FURCHAT;
        
      console.log('Detected host:', detectedHost);
      return detectedHost;
    };

    const host = detectHost();
    setCurrentHost(host);
  }, [window.location.hostname, window.location.search]);

  return currentHost;
};